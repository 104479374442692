import { NavigateFunction } from 'react-router-dom';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';
import { IAudit } from './audit.interface';
import { AxiosResponse } from 'axios';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');

export default class AuditAPI {
  // Declaring the sub-path
  public subPath: string = '/auditHistory';

  public async getAuditData(
    loginStartDate: string,
    loginEndDate: string,
    federatedIdentityStartDate: string,
    federatedIdentityEndDate: string,
    setTokenExpired: Function
  ): Promise<Array<IAudit | null>> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'GET');
      const url: string = this.createUrl(
        this.subPath,
        loginStartDate,
        loginEndDate,
        federatedIdentityStartDate,
        federatedIdentityEndDate
      );
      const response: AxiosResponse<any, any> = await axiosWithRoles.get(url);
      const data = response.data;
      return data;
    } catch (error: any) {
      console.error(error);
      if (error.code === 'ERR_NETWORK') {
        setTokenExpired(true);
        return [];
      }
      alert(error);
    }
    return await this.getAuditData(
      loginStartDate,
      loginEndDate,
      federatedIdentityStartDate,
      federatedIdentityEndDate,
      setTokenExpired
    );
  }

  // Binding the Webserver path and sub-path

  private createUrl(
    params?: string,
    loginStartDate?: string,
    loginEndDate?: string,
    federatedIdentityStartDate?: string,
    federatedIdentityEndDate?: string
  ): string {
    let url = backEndServerUrl + params;
    if (loginStartDate && loginEndDate) {
      url += `?loginStartDate=${loginStartDate}&loginEndDate=${loginEndDate}`;
    }
    if (federatedIdentityStartDate && federatedIdentityEndDate) {
      url += `${
        loginStartDate && loginEndDate ? '&' : '?'
      }federatedIdentityStartDate=${federatedIdentityStartDate}&federatedIdentityEndDate=${federatedIdentityEndDate}`;
    }
    return url;
  }
}
