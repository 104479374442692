import React, { useState } from 'react';
import CustomPopup from './BtAdminPanel/CustomPopup';
import ImageTag from '../library/ImageTag/ImageTag';
import GLlogo from '../assets/img/GLlogo.jpg';
import { GlobalLogicKeyPoints } from '../utils/Helper/enums';

interface Props {
  className?: string;
}

const InfoPopUp = ({ className }: Props) => {
  const [showPopup, setshowPopup] = useState(false);
  const openPopUp = () => {
    setshowPopup(true);
  };
  const closePopUp = () => {
    setshowPopup(false);
  };
  return (
    <div>
      <div>
        <div className="">
          <p className={className ?? 'text-black text-lg mr-2 lg:mr-0'}>
            {GlobalLogicKeyPoints.heading}{' '}
            <button
              className="border-none p-0 m-0 outline-none focus:border-none focus:outline-none underline underline-offset-2 hover:underline-offset-4 transition-all ease-in-out"
              onClick={openPopUp}
            >
              Click here for more details
            </button>
          </p>
        </div>
      </div>
      <CustomPopup show={showPopup} width="w-full max-w-5xl" onClose={closePopUp}>
        <div className="max-w-4xl xl:max-w-5xl">
          <div className="w-full gap-2 border-b">
            <div className="h-fit w-fit -ml-4">
              <ImageTag
                name="Global Logic Logo"
                className="w-60 aspect-auto mix-blend-multiply"
                src={GLlogo}
                alt="Global Logic Logo"
              />
            </div>
            <h2 className="text-black text-xl lg:text-2xl pb-2 font-bold">
              {GlobalLogicKeyPoints.heading}
            </h2>
          </div>
          <ul className="leading-7 pl-4 pt-2">
            <li className="list-item list-disc pt-1 xl:text-lg">
              {GlobalLogicKeyPoints.recognize}
            </li>
            <li className="list-item list-disc pt-2 xl:text-lg">{GlobalLogicKeyPoints.since}</li>
            <li className="list-item list-disc pt-2 xl:text-lg">
              {GlobalLogicKeyPoints.accelerate}
            </li>
            <li className="list-item pt-2 xl:text-lg">
              <a
                href="http://globallogic.com/broadband"
                target="_blank"
                rel="noreferrer"
                className="underline underline-offset-1 hover:underline-offset-2 transition-all ease-in-out"
              >
                Click here
              </a>{' '}
              for more information
            </li>
          </ul>
        </div>
      </CustomPopup>
    </div>
  );
};

export default InfoPopUp;
