import { createContext } from 'react';

export interface LoginContextType {
  loginToken: string;
  tokenExpired: boolean;
  setTokenExpired: (value: boolean) => void;
  updateToken: (value: string) => void;
}

export const LoginContext = createContext<LoginContextType>({
  loginToken: '',
  tokenExpired: false,
  setTokenExpired: function (value: boolean): void {
    throw new Error('Function not implemented.');
  },
  updateToken: function (value: string): void {
    throw new Error('Function not implemented.');
  }
});
