import React, { useContext, useEffect, useState } from 'react';
import ImageTag from '../library/ImageTag/ImageTag';
import Button from '../library/Button/button';
import create from '../assets/img/create.png';
import { AdminUserForm } from './BtAdminPanel/AdminUsers';
import { EditAdminUserForm } from './BtAdminPanel/EditAdminUsers';
import { AssignedModuleForm } from './BtAdminPanel/AssignedModules';
import { DeleteOrg } from './BtAdminPanel/DeleteOrganisation';
import { EditUserForm } from './AdminPanel/EditUser';
import { DeleteUser } from './AdminPanel/DeleteUser';
import AllOrganizationAdmin from '../utils/OrganizationUser/AllOrganisationUser';
import { OrgUserForm } from './BtAdminPanel/OrgUsers';
import ResetMfa from './BtAdminPanel/ResetMfa';
import OrgUpdate from './BtAdminPanel/OrgUpdate';
import { useLocation } from 'react-router-dom';
import Preset from '../utils/ModulePresets/Preset';
import { IPreset } from '../utils/ModulePresets/preset.interface';
import { LoginContext } from '../context/IppContext';

interface UserRowProps {
  name: string | null;
  email: string | null;
  role: string | null;
  status: string | null;
  level: string | null;
  progress: string | null;
  icon: any;
  module: any;
  buttonStatus: string | null;
  title?: string | null;
  fname?: string | null;
  lname?: string | null;
  jobTitle?: string | null;
  phone?: string | null;
  companyHaedquaterAddress?: string | null;
  description?: string | null;
  moduleList?: any;
  companyName: string;
  orgEmail: string;
  onCompanyEmailChange: (email: string) => void;
  onCompanyNameChange: (name: string) => void;
  disabled: boolean;
  domains?: any;
  tableName?: string | null;
  onStatusIncrement?: any;
  onStatusDecrement?: any;
  onDeleteUser?: () => void;
}

const UserRow: React.FC<UserRowProps> = ({
  name,
  email,
  role,
  status,
  level,
  progress,
  module,
  icon,
  buttonStatus,
  title,
  fname,
  lname,
  jobTitle,
  phone,
  companyHaedquaterAddress,
  description,
  companyName,
  orgEmail,
  onCompanyEmailChange,
  onCompanyNameChange,
  disabled,
  domains,
  onStatusIncrement,
  onStatusDecrement,
  onDeleteUser
}) => {
  // useState hook to store the admin users data.
  const [usersData, setUsersData]: any = useState([]);
  const [userEmail, setUserEmail] = useState<string | null>('');
  const [show, setShow] = useState<boolean>(false);
  const [showOrgUpdate, setShowOrgUpdate] = useState<boolean>(false);
  const [query, setQuery] = useState('');
  const [isShowing, setIsShowing] = useState(false);
  const [isUpdateTagShowing, setIsUpdateTagShowing] = useState(false);
  // to show loader until data get fetched.
  const [isLoading, setIsLoading] = useState(true);
  const [emailList, setEmailList] = useState<string[]>([]);
  const location = useLocation();
  const [storedLength, setStoredLength] = useState(0);
  const [presets, setPresets] = useState<IPreset[] | []>([]);
  const { setTokenExpired } = useContext(LoginContext);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const getActualLength: any = sessionStorage.getItem('Users Data Length');
      if (getActualLength !== null) {
        setStoredLength(parseInt(getActualLength));
      }
    }, 1000); // check every 1 second

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Open userlist when user clicks on the button.
  const popupOpenHandler = (email: string | null) => {
    setUserEmail(email);
    setShow(true);
  };

  // Open update org popup when user clicks on the button.
  const popUpdateOrgHandler = () => {
    setUserEmail(email);
    setShowOrgUpdate(true);
  };

  useEffect(() => {
    // Update the companyName and orgEmail in the parent component (AdminPanel)
    if (companyName) {
      onCompanyNameChange(companyName);
    }
    if (orgEmail) {
      onCompanyEmailChange(orgEmail);
    }
  }, [companyName, orgEmail, onCompanyNameChange, onCompanyEmailChange]);
  // Fetching data from db and mapping it to the array. Here the promise returned is void.

  useEffect(() => {
    if (userEmail && (storedLength > 0 || userEmail)) {
      const getAdminUsers = async () => {
        const organizationAdminObj = new AllOrganizationAdmin({ email: userEmail });
        const result = await organizationAdminObj.getAllAdminUser(setTokenExpired);
        const partnerEmailList: string[] = result.map((user) => {
          return user?.email ?? '';
        });
        partnerEmailList.push(userEmail ?? '');
        setEmailList(partnerEmailList);
        setUsersData(result);
        setIsLoading(false);
      };
      void getAdminUsers();
    }
  }, [userEmail, storedLength]);

  useEffect(() => {
    const fetchPrestes = async () => {
      try {
        setIsLoading(true);
        const presetInst = new Preset();
        const response = await presetInst.getAllPreset();
        setPresets(response ?? []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };
    if (location.pathname === '/btadmin') {
      void fetchPrestes();
    }
  }, []);

  return (
    <tr>
      <td className="px-6 py-4 text-center">
        {' '}
        {/* <p>{name}</p> */}
        {location.pathname === '/btadmin' ? (
          <Button
            className="underline"
            onclick={() => popUpdateOrgHandler()}
            onMouseEnter={() => setIsUpdateTagShowing(true)}
            onMouseLeave={() => setIsUpdateTagShowing(false)}
          >
            {name}
            {isUpdateTagShowing && (
              <div className="group flex relative">
                <span
                  className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2
        -translate-x-1/2 translate-y-full m-4 mx-auto"
                >
                  Click to update the domain
                </span>
              </div>
            )}
          </Button>
        ) : (
          <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
            {name}
          </p>
        )}
        {showOrgUpdate && (
          <OrgUpdate
            email={email}
            name={name}
            show={showOrgUpdate}
            setShow={setShowOrgUpdate}
            userTitle={title}
            userFname={fname}
            userLname={lname}
            orgPartnerCompanyName={name}
            orgDescription={description}
            orgCompanyHaedquaterAddress={companyHaedquaterAddress}
            domains={domains}
            emailList={emailList}
          />
        )}
      </td>
      {/* Conditional rendering starts */}
      {email === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <p className=""> {email} </p>{' '}
        </td>
      ) : (
        <td className="px-6 py-4 text-center ">
          {' '}
          <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
            {' '}
            {email}{' '}
          </p>{' '}
        </td>
      )}
      {role === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <p className=""> {role} </p>{' '}
        </td>
      ) : (
        <td className="px-6 py-4 text-center ">
          {' '}
          <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
            {' '}
            {role}{' '}
          </p>{' '}
        </td>
      )}
      {status === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <p className=""> {status} </p>{' '}
        </td>
      ) : (
        <td className="px-6 py-4 text-center">
          {' '}
          <Button
            onclick={() => popupOpenHandler(email)}
            onMouseEnter={() => setIsShowing(true)}
            onMouseLeave={() => setIsShowing(false)}
          >
            {status}
            {isShowing && (
              <div className="group flex relative">
                <span
                  className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2
        -translate-x-1/2 translate-y-full m-4 mx-auto"
                >
                  Click on Edit/Delete user
                </span>
              </div>
            )}
          </Button>
          <OrgUserForm
            usersData={usersData}
            query={query}
            disabled={disabled}
            show={show}
            setShow={setShow}
            isLoading={isLoading}
            onStatusDecrement={onStatusDecrement}
          />
        </td>
      )}
      {level === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <p className=""> {level} </p>{' '}
        </td>
      ) : (
        <td className="px-6 py-4 text-center ">
          {' '}
          <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
            {' '}
            {level}{' '}
          </p>{' '}
        </td>
      )}
      <td className="px-6 py-4 text-center hidden">
        {' '}
        <div className="bg-[#999999] rounded-2xl   w-full">
          <p className="bg-[#2196F3] text-[12px] rounded-2xl text-white" style={{ width: '70%' }}>
            {' '}
            {progress}{' '}
          </p>{' '}
        </div>
      </td>
      {module === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <p className=""> {module} </p>{' '}
        </td>
      ) : (
        <td className="px-6 py-4 text-center ">
          <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
            {module}
          </p>
        </td>
      )}
      {icon === undefined ? (
        <td className="px-6 py-4 text-center hidden">
          <div className="">
            <ImageTag src={icon} width={'28px'} alt={''} name={'Icon'} />
          </div>
        </td>
      ) : (
        <td className="px-6 py-4 text-center">
          <div className="">
            <ImageTag src={icon} width={'28px'} alt={''} name={'Icon'} />
          </div>
        </td>
      )}
      {buttonStatus === null ? (
        <td className="px-6 py-4 text-center">
          {' '}
          <div className="">
            <EditUserForm
              orgPartnerCompanyEmail={orgEmail}
              userName={name}
              orgJobTitle={role}
              orgPartnerCompanyName={companyName}
              userEmail={email}
              disabled={disabled}
              userPhone={phone}
            />
            <DeleteUser
              userEmail={email}
              orgEmail={orgEmail}
              disabled={disabled}
              onDeleteUser={onDeleteUser}
            />

            <Button className={'hidden'}>
              <ImageTag src={create} width={'20px'} alt={''} name={''} />
            </Button>
          </div>
          <Button
            className={'text-white bg-black rounded-[8px] text-[12px] px-1 -ml-[34px] hidden'}
            buttonText={buttonStatus ?? ''}
            onclick={function (e: React.FormEvent<Element>): void {
              throw new Error('Function not implemented.');
            }}
          />
        </td>
      ) : (
        <td className="px-6 py-4 text-center">
          <div className="ml-2 pr-5">
            <EditAdminUserForm
              userTitle={title}
              userFname={fname}
              userLname={lname}
              orgJobTitle={jobTitle}
              orgPhone={phone}
              orgEmail={email}
              orgPartnerCompanyName={name}
              orgDescription={description}
              orgCompanyHaedquaterAddress={companyHaedquaterAddress}
            />
            <DeleteOrg organisationName={name} organisationEmail={email} />
            <AdminUserForm
              organisationName={name}
              organisationEmail={email}
              domains={domains}
              onStatusIncrement={onStatusIncrement}
            />
            <ResetMfa userEmail={email} disabled={disabled} />
          </div>
          <AssignedModuleForm organisationName={name} presets={presets} />
        </td>
      )}
      {/* Conditional rendering ends */}
    </tr>
  );
};

export default UserRow;
