import axios, { AxiosResponse } from 'axios';

// Connecting to backend server
const backEndServerUrl = process.env.REACT_APP_BACK_END_SERVER_URL;

export class Btadmin {
  public jwtToken = sessionStorage.getItem('jwtToken');
  public preferredRole = sessionStorage.getItem('preferredRole');
  public apiKey = String(process.env.REACT_APP_API_KEY ?? '');
  public async getAdmindetails(orgEmail: string): Promise<any> {
    try {
      // creating header to wrap during the api call.
      const config = {
        headers: {
          Authorization: this.jwtToken,
          'x-api-key': this.apiKey,
          'Role-Name': this.preferredRole
        }
      };
      const url = `${backEndServerUrl}/organization/btadmin/${orgEmail}`;
      // const axiosWithRoles = apiCallWrapper(url, 'GET', orgEmail);
      const response: AxiosResponse<any, any> = await axios.get(url, config);
    } catch (error: any) {
      alert(error);
    }
  }

  public async updateNotificationToken(orgEmail: string, notificationToken: string): Promise<any> {
    try {
      // creating header to wrap during the api call.
      const config = {
        headers: {
          Authorization: this.jwtToken,
          'x-api-key': this.apiKey
        }
      };
      const url = `${backEndServerUrl}/organization/btadmin/notificationtoken/${orgEmail}`;
      // const axiosWithRoles = apiCallWrapper(url, 'GET', orgEmail);
      const requestBody: any = { notificationToken };
      const response: AxiosResponse<any, any> = await axios.put(url, requestBody, config);
      return {
        status: 201,
        message: 'Updated Successfully'
      };
    } catch (error: any) {
      return {
        status: error?.response?.status,
        message: 'Notification token update failed.'
      };
    }
  }

  public async updateActiveStatus(orgEmail: string, activeStatus: boolean): Promise<any> {
    try {
      // creating header to wrap during the api call.
      const config = {
        headers: {
          Authorization: this.jwtToken,
          'x-api-key': this.apiKey
        }
      };
      const url = `${backEndServerUrl}/organization/btadmin/updateactivestatus/${orgEmail}`;
      const requestBody: any = { activeStatus };
      const response: AxiosResponse<any, any> = await axios.put(url, requestBody, config);
      return {
        status: 201,
        message: 'Active status update Successfully'
      };
    } catch (error: any) {
      return {
        status: error?.response?.status,
        message: 'Active status update failed.'
      };
    }
  }
}
