import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// ToDo: we need to add these keys in an AWS key management service.
const firebaseConfig = {
  apiKey: 'AIzaSyAJtORwh87GFHdIuJyq36caykq0r9UcHSQ',
  authDomain: 'indigopartnerportal.firebaseapp.com',
  projectId: 'indigopartnerportal',
  storageBucket: 'indigopartnerportal.firebasestorage.app',
  messagingSenderId: '47922383513',
  appId: '1:47922383513:web:487f92c1f3dc5151bec608'
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestNotificationPermission = async (): Promise<string | null> => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, { vapidKey: 'BN9MmFAgF5IHo8elFGNgmASBasiWCfWRxkMqrh-m5wlRkiOlQeIh2WdwAdlRRRtjQ-4hnaDHD83CeLMuOXge2y0' });
      return token;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export { messaging };
