import React from 'react';
import AreasOfExperties from '../components/AreasOfExperties';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ImageTag from '../library/ImageTag/ImageTag';
import home from '../assets/img/dashboard_banner.png';
import section from '../assets/img/section.png';
import aws from '../assets/img/aws.png';
import aws_ecr from '../assets/img/aws_ecr.png';
import WelcomeHeader from '../components/TopBar/WelcomeHeader';
import { IPPHeading, IPPParagraph, IPPSubHeading } from '../library/Heading/Heading';
import {
  DashboardHeadings,
  DashboardSubHeadings,
  DashboardText
} from '../../src/utils/Helper/enums';
import InfoPopUp from '../components/InfoPopUp';

const Dashboard: React.FC<any> = () => {
  return (
    <div className="w-full">
      <div className="w-full mx-auto">
        <Header>
          <WelcomeHeader />
        </Header>
        <div className="relative bg-opacity-50 lg:grid lg:grid-cols-8 lg:gap-4 w-full ">
          <ImageTag
            src={home}
            className="absolute -z-10 object-cover w-full h-screen lg:h-[39rem] bg-center bg-cover bg-repeat max-h-[800px]"
            alt="Home"
            height="auto"
            maxWidth="100%"
            name={'Home'}
          />
          <div className="h-10"></div>
          <div className="col-span-8 p-4 flex flex-col justify-center lg:w-1/2">
            <IPPHeading
              headerText={DashboardHeadings.ipp}
              className={
                'my-6 text-2xl text-white md:text-4xl lg:text-4xl font-bold lg:mt-5 lg:mb-2 lg:my-0'
              }
            />
            <IPPParagraph
              headerText={DashboardText.ThreePartners}
              className={'text-white text-md lg:text-lg mb-10 lg:mb-6 my-6'}
            />
          </div>
        </div>
        <div className="relative mt-60 bg-white rounded-t-md">
          <div className="p-4 pt-10">
            <IPPHeading
              headerText={DashboardSubHeadings.industry}
              className={
                'my-6 text-3xl text-black md:text-3xl lg:text-3xl font-bold lg:mt-0 lg:my-0'
              }
            />
            <div className="p-2 px-0 md:px-4 lg:px-8 grid lg:grid-cols-4 md:grid-cols-2 gap-4">
              <AreasOfExperties />
            </div>
          </div>
        </div>
        <div className="bg-gray pt-14 pb-14 pl-2 rounded-t-lg">
          <div className="flex justify-between flex-wrap">
            <div>
              <IPPSubHeading
                headerText={DashboardSubHeadings.professionalSuccess}
                className={'text-black text-3xl lg:text-4xl font-bold mr-2 px-3.5 lg:mr-0 mb-6'}
              />
              <InfoPopUp className="text-black lg:text-lg pl-4" />
            </div>
          </div>
        </div>
        <div className="bg-white pt-10 pb-5">
          <div className="relative lg:grid md:grid ml-2 md:ml-0 lg:ml-2 lg:grid-cols-8 gap-4 w-full mb-6">
            <div className="col-start-0 col-span-8 flex flex-row lg:justify-between md:justify-between mr-1 lg:mx-0 lg:pb-0 ml-2 md:ml-0 lg:ml-0">
              <IPPSubHeading
                headerText={DashboardSubHeadings.codeAccess}
                className={'text-black text-3xl lg:text-4xl font-bold mr-2 px-3.5 lg:mr-0'}
              />
            </div>
          </div>
          <div className="relative lg:grid md:grid ml-2 md:ml-0 lg:ml-2 lg:grid-cols-8 gap-4 w-full mt-2">
            <div className="col-start-0 col-span-8 flex flex-row lg:justify-between md:justify-between mr-1 lg:mx-0 lg:mt-0 pb-5 lg:pb-0 ml-2 md:ml-0 lg:ml-0">
              <IPPParagraph
                headerText={DashboardText.CollaborateWithThreePartners}
                className={'text-black mb-10 lg:mb-10 my-0 px-3.5'}
              />
            </div>
          </div>
        </div>
        <ImageTag src={section} alt="Section" width="100%" name={'Section'} />
        <div className="bg-white pt-10 pb-5">
          <div className="relative lg:grid md:grid ml-2 md:ml-0 lg:ml-2 lg:grid-cols-8 gap-4 w-full mb-6">
            <div className="col-start-0 col-span-8 flex flex-row lg:justify-between md:justify-between mr-1 lg:mx-0 lg:pb-0 ml-2 md:ml-0 lg:ml-0">
              <IPPSubHeading
                headerText={DashboardSubHeadings.colabration}
                className={'text-black text-3xl lg:text-4xl font-bold mr-2 px-3.5 lg:mr-0'}
              />
            </div>
          </div>
        </div>
        <div className="relative lg:grid md:grid ml-2 md:ml-0 lg:ml-2 lg:grid-cols-8 gap-4 w-full mt-2">
          <div className="col-start-0 col-span-8 flex flex-row lg:justify-between md:justify-between mr-1 lg:mx-0  mt-0 lg:mt-0 pb-5 lg:pb-0 ml-2 md:ml-0 lg:ml-0">
            <IPPParagraph
              headerText={DashboardText.ProvideAccessToIndigoSourceCode}
              className={'text-black mb-20 lg:mb-14 my-0 px-3.5 -mt-[25px]'}
            />
          </div>
        </div>

        <div className="flex flex-row lg:grid-cols-2 gap-2">
          <ImageTag
            className="px-3.5 pl-0 md:pl-0 lg:pd-0 -mt-10"
            src={aws}
            alt="Commit"
            width="20%"
            height="30%"
            name={'AWS'}
          />
          <ImageTag
            className="px-3.5 pl-0 md:pl-0 lg:pd-0 -mt-10"
            src={aws_ecr}
            alt="Ecr"
            width="20%"
            height="30%"
            name={'AWS'}
          />
        </div>

        <div className="relative bg-gray md:grid  lg:grid lg:grid-cols-8 gap-4 py-4 md:py-8 lg:py-4 border-l-4 border-red "></div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
