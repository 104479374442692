export enum UserType {
  BtAdmin = 0,
  OrganizationAdmin = 1,
  OrganizationUser = 2
}

export enum ModuleAccessLevel { // for more info on the access levels see:  https://wiki.tvac.bt.co.uk/x/gIiKD
  NOACCESS = 'No Access',
  FULLACCESS = 'Full Access',
  PARTIALACCESS = 'Partial Access'
}

export enum BroadCastChannel {
  BROADCAST_CHANNEL_NAME = 'logoutChannel'
}

export enum InitialFlag {
  Admin = 'admin',
  User = 'user',
  BtAdmin = 'btAdmin'
}
export enum JobTitle {
  Developer = 'Developer',
  Admin = 'Admin'
}

export enum Title {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Miss = 'Miss',
  Ms = 'Ms',
  Dr = 'Dr',
  Prof = 'Professor',
  Sir = 'Sir'
}

export enum responseMessages {
  CreateUserSuccess = 'User Created Successfully',
  UpdateUserSuccess = 'User Updated Successfully',
  UserExists = 'User already exists in the Database',
  FeedbackSubmitted = 'Your feedback is submitted successfully',
  SomethingWentWrong = 'Something went wrong'
}

export enum DashboardText {
  ThreePartners = 'Our three great brands - BT, EE and Plusnet enable millions of customers to use the internet. Our best-in-class routers and Wi-Fi extenders enable devices to connect seamlessly and safely. Intelligent software empower our customers to manage and optimize their service. Indigo is our software platform powering this experience.',
  CollaborateWithThreePartners = 'We collaborate with the best partners in industry to create innovative new products and services for our customers. Join the Indigo Partner Portal and access to the Indigo source code. Build something new as you combine your product with Indigo. This can happen within the context of an existing business relationship or if you want to start something new with us. Our door is open.',
  ProvideAccessToIndigoSourceCode = 'We provide access to the Indigo source code using AWS CodeCommit. The Partner Portal provides the tools you need to manage users and access tokens. AWS CodeCommit is built on git which is perfect for multi-organisation collaboration. We also provide a pre-made build environment so it’s easy to get started in building firmware images. This comes in the form of a Docker container hosted in the AWS Elastic Container Registry.'
}

export enum DashboardHeadings {
  ipp = 'Indigo Partner Portal'
}

export enum DashboardSubHeadings {
  colabration = 'We provide the tools to aid collaboration',
  codeAccess = 'Access our code and create something new',
  professionalSuccess = 'Partner Portal Professional Services',
  industry = 'We work with Industry'
}

export enum GlobalLogicKeyPoints {
  heading = 'BT endorses GlobalLogic as a provider of professional services to aid partners in developing on the Indigo platform.',
  recognize = 'BT recognises that not all Partners will be in a position to carry out development work on the Indigo platform for themselves.',
  since = 'Since 2022, GlobalLogic has successfully collaborated with BT on Indigo-based projects.',
  accelerate = 'Working with GlobalLogic can accelerate and enhance the quality of Partner Portal projects by leveraging their experience.'
}

export enum CardContent {
  SiliconProviders = 'At the core of all CPE hardware are the chipsets e.g. SoC, Wi-fi, Dect, Iot. The strategy for Indigo is to support a diversity of chipsets to allow us maximum choice when building new products.',
  DeviceManufacturers = 'We work with ODM partners to build these for us. The ODMs in turn work with the silicon manufacturers. Together they provide the platform of hardware and low-level software that Indigo runs on.',
  SpecialistSoftwareProviders = 'When appropriate Indigo may make use of specialist software modules to provide the features required. Examples include software DSP for voice, MPTCP, speed test.',
  ThirdPartyAppSuppliers = 'Indigo includes a container framework to allow sandboxed applications to excute on the CPE. These can interact with the device via a high-level API based on USP.'
}
export enum changePassDisplayMessage {
  successMessage = 'Password changed successfully',
  btAdminErrorMessage = 'Failed to change password',
  userErrorMessage = 'Something went wrong. Please try after sometime'
}
